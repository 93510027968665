// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();

var $ = require("jquery");
window.jQuery = $;
window.$ = $;

import toastr from "toastr";
toastr.options = {
  closeButton: true,
  debug: false,
  newestOnTop: false,
  progressBar: false,
  positionClass: "toast-top-right",
  preventDuplicates: false,
  onclick: null,
  showDuration: "600",
  hideDuration: "1000",
  timeOut: "5000",
  extendedTimeOut: "1000",
  showEasing: "swing",
  hideEasing: "linear",
  showMethod: "fadeIn",
  hideMethod: "fadeOut",
};

global.toastr = toastr;

const DEMANDAS_BUSCA_VISIVEL = "demandas_busca_visivel";

$(document).ready(function() {
  // expandir e diminuir menu celular admin
  $(".menu-media").click(function() {
    $(".menu").slideToggle();
    $(".menu").toggleClass("ativo");
  });

  const estadoAtualBusca = localStorage.getItem(DEMANDAS_BUSCA_VISIVEL);

  if (estadoAtualBusca === "true") {
    $(".form-pesquisa").slideToggle(0);
    $(this)
      .children("svg")
      .toggleClass("inverte-seta");
  }

  // expandir e diminuir menu celular pesquisa
  $(".fieldset-cabecalho").click(function() {
    $(".form-pesquisa").slideToggle();
    $(this)
      .children("svg")
      .toggleClass("inverte-seta");
    const buscaVisivel = localStorage.getItem(DEMANDAS_BUSCA_VISIVEL);

    localStorage.setItem(
      DEMANDAS_BUSCA_VISIVEL,
      !buscaVisivel || buscaVisivel === "false" ? "true" : "false"
    );
  });
});

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
